import React from 'react'

function Dialog({ open, onClose, onConfirm, message, messageTheme, item=undefined }) {
  if (!open) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog">
        <span className="dialog-close" onClick={onClose}>
          &times;
        </span>
        <h2>{messageTheme}</h2>
        <p>Are you sure you want to {message} {item && <span>{item}</span>}</p> 
        {messageTheme && (<button className="dialog-button-positive" onClick={onConfirm}>{messageTheme}</button>)}
        <button className="dialog-button-negative" onClick={onClose}>No, cancel</button>
      </div>
    </div>
  );
}

export default Dialog;