import React, { Component, useEffect, useRef, useState } from 'react';
import { useAuth0, Auth0Context } from "@auth0/auth0-react";


import axios from 'axios';


import { MdDelete } from "react-icons/md";

import Dialog from './components/Dialog';

require('dotenv').config();
// import { sendMessage } from './mailserver';
// const {sendMessage} = require('./mailserver.js');

// import { sendMessage } from '../../../22 estates backend/mailserver';

// const someData = [
   
// ]


let emailOptions = [];

let emailIndex = 0;



export default function RegisterProperty(){  
    const { getAccessTokenSilently } = useAuth0();

    //promijeniti u false
    const [isEmailValid, setIsEmailValid] = useState(true);

    const [accessToken, setAccessToken] = useState(null);
    const [managementToken, setManagementToken] = useState(null);

    const [tableData, setTableData] = useState([
    
    ]);
  
    
    // const [searchInput, setSearchInput] = useState('');
    const [propertyData, setPropertyData] = useState({
        propertyName: '',
        email: '',
        name: '',
        surname: '',
        isResetDialogOpen: false,
        isDeleteDialogOpen: false,
        deleteItem: '',
        filteredOptions: '',
        userID: null,
        Auth0UserID: null,
    });

        // Load data
        // useEffect(() => {
            const loadTableData = () => {
            if (!accessToken) return; // Do nothing if accessToken is not set yet

            setTableData([])
            axios.get('https://homeowner.22estates.com/api/api/getUsers', {
            // axios.get('http://localhost:3001/api/getUsers', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(response => {
            //   setPropertyData(response.data);
                response.data.map((item, index) => {
                    emailOptions.push(item.email);
                    setTableData(prevState => [...prevState, {
                        userID: item.id,
                        email: item.email,
                        name: item.name,
                        surname: item.surname,
                        propertyName: item.propertyName
                    }]);
                }
                )
            })
            .catch(error => {
              console.error('There was an error!', error);
            });
        }
        // }, []);

        // Use useEffect to call getAccessToken when the component mounts
        useEffect(() => {
            getAccessToken();
        }, []); // The empty array means this effect runs once on mount


        useEffect(() => {
             // load in the user data from the database
            //  need to be optimized
             loadTableData();
        }, [accessToken]); // This effect runs when accessToken changes

        // get the management token
        useEffect(() => {
            if (!accessToken) return; // Do nothing if accessToken is not set yet
        
            const fetchManagementToken = async () => {
                try {
                    const response = await fetch('https://homeowner.22estates.com/api/api/getManagementAccessToken', {
        
                    // const response = await fetch('http://localhost:3001/api/getManagementAccessToken', {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    const data = await response.json();
                    setManagementToken(data.access_token); // Assuming setManagementToken updates state

                   

                } catch (error) {
                    console.error('Error fetching management token:', error);
                }
            };
        
            fetchManagementToken();
        }, [accessToken]); // This effect runs when accessToken changes


const getAccessToken = async () => {
    const domain = "dev-u2xbt2hohxgza2ou.us.auth0.com";

    try {
        // Get the access token from the Auth0 SDK
        const response = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${domain}/api/v2/`,
            //   clientId:"DuiqlcZmZujZFX2z5V2iBKpxFpU8w46B",
              scope: "create:users read:current_user update:current_user_metadata",
            },
          });
        setAccessToken(response)


        
        // const response = await fetch('https://homeowner.22estates.com/api/api/getAccessToken');
        // const data = await response.json();
        // setAccessToken(data.access_token);
        // Do something with the access token
    } catch (error) {
        console.error('Error getting access token:', error);
    }

    
};


    const handleInputChange = (event) => {
        // rewrite everything
        // const { email, name, value } = event.target;
        emailIndex = event.target.value.length;
        

        setPropertyData(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
            filteredOptions: emailOptions.filter(option =>
                event.target.name === "email" ? option.toLowerCase().includes(event.target.value.toLowerCase()) : ''
            ),
        }))

    };

    // Function to check if the property name already exists
    const propertyNameExists = (newPropertyName) => {
        return tableData.some(item => item.propertyName === newPropertyName);
    };

    const emailExists = (newEmail) => {
        return tableData.some(item => item.email === newEmail);
    };
    const getUserID = (newEmail) => {
        // In case the email exists, return the userID
        // Error that is avoided by using this: 
        // If you enter a property that exists and a new email, the userID will be null
        return emailExists(newEmail) ? tableData.filter(item => item.email === newEmail)[0].userID : null;
    };

    

    async function registerUser(email) {
        let password = generatePassword();
        const url = 'https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/users';
        const data = {
            email: email,
            password: password,
            connection: 'Username-Password-Authentication', // Update this to your Auth0 database connection name
            // Add additional user profile fields if needed
        };
        // const accessToken = await getAccessToken();
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Bearer ${managementToken ? managementToken : ''}`,
            // 'scope': 'create:users',
         
        };
    
        try {
            const response = await axios.post(url, data, { headers: headers });
              // call the function to register user
              
    
            sendEmail(propertyData.email, '22 Estates Web', 'Welcome to 22 Estates.', password);
            // send an email from here
            // mailserver.sendMessage(`Welcome to 22 Estates! Your data is: \n Email: ${email} \n password: + ${password}`,
            // 'Welcome to 22 Estates!', "Registration", email
            // )
            
            // return true if the user is created
            // so the values can be returned and stored in the table
              return true;
    
        } catch (error) {
            console.error('Error creating user:', error.response ? error.response.data : error.message, password);
            if(error.response.status === 400){
                while(error.response.status === 400){
                    // regenerate a new password
                    data.password = generatePassword();
                    
                    try {
                        const response = await axios.post(url, data, { headers: headers });
                        // call the function to register user
                        if(response) {
                            sendEmail(propertyData.email, '22 Estates Web', 'Welcome to 22 Estates.', data.password);

                               // return true if the user is created
                         // so the values can be returned and stored in the table
                            return true;
                        }
                        // send an email from here
                        // mailserver.sendMessage(`Welcome to 22 Estates! Your data is: \n Email: ${email} \n password: + ${password}`,
                        // 'Welcome to 22 Estates!', "Registration", email
                        // )
                    } catch (error) {
                        console.error('Error creating user:', error.response ? error.response.data : error.message, password);
                    }
                }
            }if(error.response.status === 409) {
                alert('User already exists');                
                return false;
            }
        }
    }
    function generatePassword() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
        let password = '';
        
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters[randomIndex];
        }
        
        return password;
    }




    const handleSubmit = (e) => {
        e.preventDefault();
        if(propertyData.email === '' || propertyData.propertyName === '' 
        || propertyData.name === '' || propertyData.surname === ''){
            alert('Missing data!');
            return;
        }
        else {
        // Here you can add the logic to save the property data to the database
        propertyData.userID = propertyData.email ? getUserID(propertyData.email) : null;

        // Reset the form after submitting
        
        // someData.push(propertyData);

        if (propertyNameExists(propertyData.propertyName)) {
            // Add a dialog
            alert('Property name already exists');
            return;
        } else {
            // Add role "user" to propertyData
            propertyData.role = "user";

            if (emailExists(propertyData.email)) {
                axios.post('https://homeowner.22estates.com/api/api/properties', propertyData, {
                // axios.post('http://localhost:3001/api/properties', propertyData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => {
                        // Handle success
                        setTableData([...tableData, propertyData])

                        alert('Property registered successfully');

                        // Refresh the application window
                        window.location.reload();
                        
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                        // Handle error
                    });
                //  if email exists, add to the database and table the data without registration
                
            } else {
                registerUser(propertyData.email).then(registerSuccessful => {

                    if(registerSuccessful){
                        axios.post('https://homeowner.22estates.com/api/api/addUserWithProperty', propertyData, {
                        // axios.post('http://localhost:3001/api/addUserWithProperty', propertyData, {
                            
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => {
                                // Handle success
                                propertyData.userID = response.data.userID;

                                setTableData([...tableData, propertyData])
                            })
                            .catch(error => {
                                console.error('There was an error!', error);
                                // Handle error
                            });
    
                        //  if it doesnt exists, send an email registration and add to the database and table
    
                        // add the new user data and property to the table
                        setTableData([...tableData, propertyData])

                        alert('User registered successfully');
                        
                        // Refresh the application window
                        // window.location.reload();
                    }
                });
                

                

            }
        }
       
        setPropertyData(prevState => ({
            ...prevState,
            propertyName: '',
            email: '',
            name: '',
            surname: '',
            isResetDialogOpen: false,
            isDeleteDialogOpen: false,
            deleteItem: '',
            filteredOptions: [],
        }));

    }
    };

    const sendEmail = async (to, subject, text, userPassword) => {
        try {
            const response = await fetch('https://homeowner.22estates.com/api/send-email', {
                // const response = await fetch('http://localhost:3001/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    
                },
                body: JSON.stringify({ to, subject, text, userPassword }),
            });

        } catch (error) {
            console.error('Error sending email:', error);
        }
    };



        const openResetDialog = (email) => {
            // this.setState({ isResetDialogOpen: true });
            setPropertyData({ ...propertyData, email: email, isResetDialogOpen: true });
          };
        const openDeleteDialog = () => {
            // this.setState({ isDeleteDialogOpen: true });
            // setPropertyData({...propertyData,isDeleteDialogOpen: true });

            setPropertyData(prevState => ({
                ...prevState,
                isDeleteDialogOpen: true
            }));
          };


        
        const closeDialog = () => {
            setPropertyData(prevState => ({
                ...prevState,
                deleteItem: '',
                isResetDialogOpen: false,
                isDeleteDialogOpen: false,
                email: '',
            }));
          };
        
        const handleResetConfirm = async () => {
            
            // Logic for resetting the password

            try {

                var data = JSON.stringify({
                client_id: 'mAi725zWtuXXHnl0SglScTvdN2hzdWA4',
                email: propertyData.email,
                connection: 'Username-Password-Authentication'
                });

                var config = {
                method: 'post',
                url: 'https://dev-u2xbt2hohxgza2ou.us.auth0.com/dbconnections/change_password',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
                };

                axios(config).then(function (response) {
                    alert('Password reset email sent');
            })
                .catch(function (error) {
                    console.error(error);
                });

               
            } catch (error) {
                console.error('Error sending password reset email:', error);
            }
            setPropertyData(prevState => ({
                ...prevState,
                deleteItem: '',
                email: '',
            }));
            closeDialog();
          };

        const handleDeleteConfirm = async () => {
            // Here you can add the logic to save the property data to the database
           
            // Reset the form after submitting 

            // uncomment the one below line
            closeDialog();

            let count = tableData.filter(item => item.email === propertyData.email).length;
            
            if(count > 1){
                // delete only from the table
                // this is called when there are multiple properties for the same user e-mail
                deleteProperty();
            }
            else {
                //Delete the user from our MySQL database, table Users
                propertyData.userID = getUserID(propertyData.email);
                axios.delete(`https://homeowner.22estates.com/api/api/users/${propertyData.userID}`, {

                // axios.delete(`http://localhost:3001/api/users/${propertyData.userID}`, {
                    headers: {
                        Authorization: `Bearer ${managementToken}`,
                    }
                })
                .then(async () => {
                    try {
                        const auth0UserID = await getUserIDByEmail(propertyData.email);
                        if (auth0UserID) {
                            // if user exists, delete it
                            await deleteUserById(auth0UserID);
                            setPropertyData(prevState => ({
                                ...prevState,
                                Auth0UserID: auth0UserID
                            }));
                            
                            alert(`User ${propertyData.email} deleted successfully`)
                        } else {
                            alert('User not found');
                        }
                    } catch (error) {
                        console.error('Error in Auth0 operations:', error);
                    }
                })
                .catch(error => {
                    // Handle errors here
                    console.error('There was an error deleting the user from the local server:', error);
                });

            }
            const updatedTableData = tableData.filter(item => item.propertyName !== propertyData.deleteItem);
            setTableData(updatedTableData);

            setPropertyData(prevState => ({
                ...prevState,
                deleteItem: '',
                email: '',
            }));
            
          }  

          const deleteProperty = () => {
            axios.delete(`https://homeowner.22estates.com/api/api/properties/${propertyData.deleteItem}`, {
            // axios.delete(`http://localhost:3001/api/properties/${propertyData.deleteItem}`, {
                headers: {
                    Authorization: `Bearer ${managementToken}`,
                    },
                    })
            .then(response => {
                alert('Property deleted successfully');
                // Handle the response (e.g., update state, show message)
            })
            .catch(error => {
                console.error('There was an error!', error);
                // Handle errors here
            });
          }
          const getUserIDByEmail = async (email) => {
        //         const url = 'https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/users';

            const url = `https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/users-by-email?email=${email}`;
            
            try {
              const response = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${managementToken ? managementToken : ''}`,

                },
              });
          
              if (response.data && response.data.length > 0) {
                return response.data[0].user_id; // Assuming the first user is the one you're looking for
              } else {
                return null; // No user found
              }
            } catch (error) {
              console.error('Error fetching user ID:', error);
              return null;
            }
          };
          const deleteUserById = async (userId) => {
            const url = `https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/users/${userId}`;
          
            try {
              await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${managementToken ? managementToken : ''}`,

                },
              });
          
            } catch (error) {
              console.error('Error deleting user:', error);
            }
          };
          
        return (
            
            <div className='register-property'>
                   
                <h2 className='register-property-heading'>Register Property</h2>
                
                <form className='userForm' onSubmit={handleSubmit}>
                        <label>
                        Property: 
                        <input
                            type="text"
                            name="propertyName"
                            value={propertyData.propertyName}
                            onChange={handleInputChange}
                        />
                        {/* {propertyData.propertyName.length === 0 && (
                            <>
                            <br />
                            <span className="errorInput">Empty value! </span>
                            </>
                            )} */}
                    </label>
                        <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={propertyData.email}
                            onChange={handleInputChange}
                            autoComplete="on"
                        />
                            {emailIndex>0 && (<div>
                                
                                {propertyData.filteredOptions.map((option) => (
                                    <div key={option} onClick={() => {
                                        //  setPropertyData({ propertyData: { ...propertyData, email: option }, 
                                        //     filteredOptions: emailOptions })
                                        //  emailIndex = 0;
                                        setPropertyData(prevState => ({
                                            ...prevState,
                                            email: option,
                                            filteredOptions: emailOptions,
                                        }),
                                        )
                                        emailIndex = 0;
                                         }}>
                                        {option}
                                    </div>
                                ))}
                            </div>)}
                    </label>

                    <label>
                        First name: 
                        <input
                            type="text"
                            name="name"
                            value={propertyData.name}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Last name: 
                        <input
                            type="text"
                            name="surname"
                            value={propertyData.surname}
                            onChange={handleInputChange}
                        />
                    </label>
                    <button className='button' type="submit" disabled={!isEmailValid} >Register</button>
                </form>

                <h2>Existing Properties</h2>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Property name</th>
                            <th>Email</th>
                            <th>First name</th>
                            <th>Last name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Add logic to render existing properties */}
                        {tableData.map((item, index) => {
                            // Add logic if the table is empty
                            return (
                                <tr key={index}>
                                    <td>{item.propertyName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.name}</td>
                                    <td>{item.surname}</td>
                                    <td><button className='reset-pw' onClick={()=>{
                                        openResetDialog(item.email);

                                    }}>Reset password</button></td>
                                    <td onClick={()=>{
                                        setPropertyData(prevState => ({
                                                ...prevState,
                                                deleteItem: item.propertyName,
                                                email: item.email,
                                                userID: item.userID
                                            }));
                                        
                                        }
                                        }>
                                        <MdDelete className='delete-button' onClick={openDeleteDialog}/>
                                    </td>
                                </tr>
                            );
                                
                        })}
                        
                    </tbody>
                </table>
     
                <Dialog
                    open={propertyData.isResetDialogOpen}
                    onClose={closeDialog}
                    onConfirm={handleResetConfirm}
                    message={'reset the user password?'}
                    messageTheme={'Reset password'}
                    />
                <Dialog
                    open={propertyData.isDeleteDialogOpen}
                    onClose={closeDialog}
                    onConfirm={handleDeleteConfirm}
                    message={'delete'}
                    messageTheme={'Delete'}
                    item={propertyData.deleteItem}
                    />
                
                
            </div>
                       
        );
    }
