import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from '@auth0/auth0-react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff0000',
    },
  },
});

const yourDomain = "localhost:3000";
const yourClientId = "dev-u2xbt2hohxgza2ou";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Auth0Provider
    domain="dev-u2xbt2hohxgza2ou.us.auth0.com"
    clientId="mAi725zWtuXXHnl0SglScTvdN2hzdWA4"
    // clientId='DuiqlcZmZujZFX2z5V2iBKpxFpU8w46B'
    // audience="https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/"
    
    scope="create:users read:current_user update:current_user_metadata"
    
    // set up audience in the authorizationParams to avoid the error:
    // with the request token not being the same as the one in the api
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/",
      
      // scope: "read:current_user",
      // scope: "read:current_user update:current_user_metadata"

    }}
  >
    {/* <ThemeProvider theme={theme}> */}
      <App />
    {/* </ThemeProvider> */}
  </Auth0Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
