// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
// import './NotFound.css'; // Import the CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">Oops! The page you are looking for does not exist.</p>
      <Link to="/" className="not-found-link"><h3>Go Back to Home</h3></Link>
      <br />
      <a href="https://22estates.com" target="_blank" rel="noopener noreferrer" className="not-found-link"><h3>Visit 22 Estates</h3></a>
    </div>
  );
};

export default NotFound;
